.carousel-slide img {
  width: 900px;
  height: 300px;
}
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 10% 0 10% 0;
}

.image-name {
  text-align: center;
  font-family: "SF-Semibold";
  font-size: 18px;
  margin-bottom: 18px;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows {
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.image-carousel .active {
  margin-top: 24px;
  width: 950px;
  height: 300px;
}

.prototype-carousel .active {
  margin-top: 24px;

  width: 900px;
  height: fit-content; /* Adjust the width of the image */
}
@media only screen and (max-width: 1284px) {
  .image-carousel .active {
    margin-top: 24px;
    width: 300px;
    height: 100px;
  }
  .arrows {
    cursor: pointer;
    font-size: 20px;
    color: #333;
    margin-top: 45px;
  }
  .prototype-carousel .active {
    margin-top: 24px;
  
    width: 300px;
    height: fit-content; /* Adjust the width of the image */
  }
}
