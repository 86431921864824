.container-filmfriends {
  background: linear-gradient(0.25turn, #f5ebe9, #c9c0dd, #c0b6d6);
  height: 100vh; /* Set the height to 100% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  overflow-x: hidden;
}

.image-filmfriendsfirst {
  height: 100%;
}


.FilmFriends-title {
  font-size: 52px;
}


.container-image {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  flex-direction: column;
}
.container-image-filmfriends{
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  margin: 2% 10% 10% 10%;
}
.appstore{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 0 6% 0;
}
.appstore img {
  height: 50px;

}

.container-introduction-text {
  font-family: SF-Semibold;
  margin: 5% 25% 5% 25%;
  line-height: 1.7;
  font-size: 20px;
}

.container-problem {
  margin: 0 25% 0% 25%;
  line-height: 1.7;
}

.section-bodytext {
  margin: 0 25% 5% 25%;
  line-height: 1.8;
}
.section-title {
  font-family: SF-Semibold;
  font-size: 24px;
  text-align: center;
  margin: 0 0 2% 0;
}
.container-competitors {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 5%;
}
.competitor {
  height: 200px;
  width: auto;
}
.competitor-text {
  text-align: center;
  margin: 5% 0 5% 0;
}

.container-figma-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.container-figma-prototypes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 5%;
}

.figma-prototype {
  height: 400px;
  width: auto;
}
.section-bodytext-final-design-introduction {
  text-align: center;
}

.final-design-introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Final-design-login {
  height: 700px;
  width: auto;
}

.Final-design-discover-image {
  height: 500px;
  width: auto;
}
.Final-design-discover {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: center;
  gap: 20px; /* Adjust the gap between image and text */
}

.Final-design-discover img {
  height: 600px;
  width: auto;
  margin: 0 0 0 -200px;
}

.final-design-text {
  margin: 0;
  width: 300px;
  line-height: 1.7;
}

.FF-swipe {
  width: 650px;
}



.Final-design-watchlist-image {
  height: 700px;
  width: auto;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Final-design-watchlist {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: center;
  gap: 20px; /* Adjust the gap between image and text */
  margin: -100px 0 0 -200px;
}

.Final-design-vote {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: center;
  gap: 100px; /* Adjust the gap between image and text */
  margin: -100px 0 0 -100px;
}

.Final-design-vote-image {
  height: 600px;
  width: auto;
  margin: 100px -200px 0 0;
}
.container-section-reflections {
  margin: 5% 0 10% 0;
}
.future-development-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.development-list-item {
  font-family: SF-Light;
  line-height: 1.8;
}

.development-list-container {
  margin: 0 25% 3% 25%;
}

.section-bodytext-future-development {
  margin: 0 25% 3% 25%;
  line-height: 1.8;
}

.development-list-item::before {
  content: "\2022"; /* Add a dot */
  margin-right: 8px;
}

/* Add a gap between each list item */
.development-list-item + .development-list-item {
  margin-top: 8px;
}
.container-developent-tools {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin: 5% 0 5% 0;
  align-items: center;
}
.development-tool {
  height: 100px;
}
.Final-design-swipe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 100px 0;
}

@media only screen and (max-width: 1284px) {
  .image-filmfriendsfirst {
    height: 100%;
    width: auto;
  }
  .FilmFriends-title {
    font-size: 40px;
  }
  .section-bodytext {
    margin: 5% 10% 5% 10%;
    line-height: 1.8;
  }
  .section-bodytext-future-development {
    margin: 0 10% 20% 10%;
    line-height: 1.8;
  }
  .development-list-container {
    margin: 0 10% 20% 10%;
  }
  .development-list-item {
    line-height: 1.8;
  }

  .section-title {
    margin-top: 15%;
  }
  .competitor {
    height: 100px;
    width: auto;
  }
  .FilmFriends-subtitle {
    font-size: 10px;
  }

  .figma-prototype {
    height: 200px;
    width: auto;
  }
  .container-introduction-text {
    font-family: SF-Semibold;
    margin: 5% 10% 5% 10%;
    line-height: 1.7;
    font-size: 18px;
  }
  .container-developent-tools {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    margin: 20% 0 20% 0;
    align-items: center;
  }
  .FilmFriends-subtitle {
    font-size: 18px;
  }

  .Final-design-login {
    height: 450px;
    width: auto;
  }
  .development-tool {
    height: 50px;
  }

  .Final-design-discover {
    display: flex;
    flex-direction: column-reverse;
    align-items: center; /* Vertically center the items */
    justify-content: center;
    gap: 20px; /* Adjust the gap between image and text */
    margin: 32px 0 32px 0;

  }

  .Final-design-discover img {
    height: 400px;
    width: auto;
    margin: 0 0 0 0;
  }

  .FF-swipe {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0 5% 0;
  }
  .FF-swipe img {
    height: 300px;
    width: auto;
  }

  .Final-design-watchlist-image {
    height: 400px;
    width: auto;
  }
  .Final-design-watchlist {
    display: flex;
    flex-direction: column-reverse;
    align-items: center; /* Vertically center the items */
    justify-content: center;
    margin: 32px 0 32px 0;
  }

  .Final-design-vote {
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertically center the items */
    justify-content: center;
    margin: 32px 0 32px 0;
    gap: 16px;
  }

  .Final-design-vote-image {
    height: 400px;
    width: auto;
    margin: 0;
  }
  .justify-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Final-design-swipe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 32px 0 32px 0;
  }
  .FF-swipe {
    width: 450px;
  }
  .final-design-introduction{
    margin: 32px 0 32px 0;

  }
}
