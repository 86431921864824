


/* Style for the email icon */
.Email-icon {
  height: 37px;
  width: 27px;
  top: -10px;
  color: #000; /* Set icon color */
}

.Email-icon:hover{
    color: #DDE6F9;
}
