.container-about {
  width: auto;
  display: flex;
  flex-direction: row; /* Adjusted to column for responsive layout */
  align-items: center; /* Center items horizontally */
  margin: 100px 15% 0 15%;
}

.hej {
  margin-bottom: 24px;
}

.about-me-text {
  margin: 0;
  max-width: 90%; /* Adjusted to a maximum width */
  line-height: 1.7;
}

.imageJenny img {
  max-width: 100%; /* Set maximum width to 100% of container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px;
}

.resume-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.View-Resume {
  padding: 20px 30px;
  background: #d1def7;
  border-radius: 100px;
  border: 2px solid transparent; /* Set initial border color to transparent */
  font-size: 18px;
  font-family: "SF-Semibold";
  color: white;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.View-Resume:hover {
  padding: 20px 30px;
  border-radius: 100px;
  background-color: transparent;
  border: 2px solid #d1def7; /* Set the border color on hover */
  font-size: 18px;
  font-family: "SF-Semibold";
  color: black;
}

@media only screen and (max-width: 1284px) {
  .container-about {
    width: auto;
    display: flex;
    flex-direction: column; /* Adjusted to column for responsive layout */
    align-items: center; /* Center items horizontally */
    margin: 80px 16px 0 16px;
    gap: 50px;
  }
.resume-container{
    margin-bottom: 50px;
}
}
