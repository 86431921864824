/* Font definitions */
@font-face {
  font-family: "SF-Semibold";
  src: url("../Fonts/SF-Pro-Text-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "SF-Light";
  src: url("../Fonts/SF-Pro-Text-Light.otf") format("opentype");
}
@font-face {
  font-family: "Adorage";
  src: url("../Fonts/Adorage.otf") format("opentype");
}

/* Site title styles */
.site-title {
  font-size: 18px;
  font-family: SF-Semibold;
  color: #433633;
}

/* Navbar styles */
.nav {
  transition: top 0.3s;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  font-size: 18px;
  color: #433633;
  font-family: SF-Semibold;
  display: flex;
  background-color: #f0efeb;
  justify-content: space-between;
  align-items: center; /* Ensure vertical centering */
  padding: 0 32px; /* Apply padding on left and right */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 16px;
  margin: 0; /* Ensure no default margin from the ul element */
  padding: 0; /* Ensure no default padding from the ul element */
}

/* Show and hide styles for navbar */
.nav.show {
  top: 0;
}

.nav.hide {
  top: -100px; /* Adjust based on your navbar height */
}

/* Link styles within navbar */
.nav a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 16px 0; /* Adjust as needed */
}

/* Hover effect for list items */
.nav li:hover {
  text-decoration: underline;
}
.FilmFriends-navbar{
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
}
.Essiq-navbar{
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
}
.about-navbar{
background-color: transparent;
}

.Canea-navbar{
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
}




/* Media queries for responsiveness */
@media only screen and (max-width: 1284px) {
  .site-title {
    font-size: 16px;
    padding: 0;
  }
  .nav ul {
    font-size: 16px;
    gap: 16px;
  }
}
