.ProjectTemplate-image-container {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .ProjectTemplate-image {
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
    display: block;
    border-radius: 5px;
  }
  
  .ProjectTemplate-image-container:hover .ProjectTemplate-image {
    transform: scale(1.03);
  }
  
.ProjectTemplate-title {
  color: white;
  font-family: "SF-Semibold";
  font-size: 16px;
}

.ProjectTemplate-subtitle {
  color: #d2d2d2;
  font-family: "SF-Text-Light";
  font-size: 12px;
}

.ProjectTemplate-text {
  margin-top: 8px;
}

.ProjectTemplate-button {
  color: white;
  font-size: 12px;
  font-family: "SF-Text-Light";
  align-self: center; /* Align the arrow vertically */
  cursor: pointer;
  text-decoration: none;
}

.arrow-casestudy {
  color: white;
  align-self: center; /* Align the arrow vertically */
  margin-left: 4px; /* Add some space between the button and the arrow */
}

.row {
  display: flex;
  margin-top: 8px;
  align-items: center; /* Align items vertically within the row */
  text-decoration: none;
}

.ProjectTemplate-button:hover {
  text-decoration: underline;
}



@media only screen and (max-width: 1284px) {
  
  }
  