.Footer-page {
    height: 35vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto; /* Center the container horizontally */
    text-align: center; /* Align text horizontally in the center */
    gap: 18px;
  }
  
  .footer-bodytext {
    line-height: 1.8;
    max-width: 500px;
    margin: 0 auto; /* Center the text horizontally */
  }
  
  .footer-title {
    margin-top: 50px;
  }
  
  .footer-email {
    font-family: "SF-Text-Light";
    color: black;
  }
  

@media only screen and (max-width: 1284px) {
    .footer-bodytext{
        max-width: 290px;
    }

  }
  