.AIChatbot-img {
    width: 100%;
    max-height: 100%;
  }
  
  .project-chatbot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    height: 100vh;
    margin: 0 100px;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .AIChatbot-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  
  
  .Readmore {
    background-color: black;
    color: white;
    padding: 19px 30px;
    width: fit-content;
    cursor: pointer;
    border-radius: 100px;
    text-decoration: none;
    font-family: "SF-Light";
    font-size: 18px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
  }
  
  .Readmore:hover {
    background-color: transparent;
    border: 1px solid black;
    color: black;
  }
  
  .Readmore:active {
    transform: scale(0.95);
  }
  
  .chatbotimage-container {
    background-color: #636363;
    display: flex;
    width: 100%;
    height: auto; /* Change to auto */
    justify-content: center;
    padding: 50px 30px;
    border-radius: 20px;
  }
  
  .chatbot-small-text {
    line-height: 1.7;
  }
  
  @media only screen and (max-width: 1284px) {
    .project-chatbot {
      flex-direction: column;
      margin: 0 24px;
      gap: 40px;
      height: 80vh;

    }
    .AIChatbot-text {
      margin: 12px 32px 12px 32px;
    }
    .chatbotimage-container {
      border-radius: 5px;
      width: fit-content;
    }
  }
  