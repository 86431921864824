.container-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-me {
  text-align: center;
  margin-bottom: 24px;
}

/* Style for the copyright name container */
.copyright-name {
  margin-top: 8px;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 4px;
}

/* Style for the copyright icon */
/* Adjust the size as needed */
/* Ensure it's centered both vertically and horizontally */
/* You can remove height and width if you want the default size of the icon */
/* Adjust other styles as needed */
.copyright-icon {
  height: 14px;
  width: 14px;
}

.linkedin-icon {
  height: 22px;
  width: 22px;
  color: black; /* Set the color of the LinkedIn icon */
  /* Optionally, you can remove text decoration for links */
  text-decoration: none;
}

.github-icon{
  height: 22px;
  width: 22px;
  color: black; /* Set the color of the LinkedIn icon */
  /* Optionally, you can remove text decoration for links */
  text-decoration: none;
}

.github-icon:hover{
  color: #dde6f9; /* Change color on hover if desired */
}

.linkedin-icon:hover {
  color: #dde6f9; /* Change color on hover if desired */
}
.Name {
  font-size: 18px;
  color: #626262;
  font-family: "SF-Light";
}
.Footer {
  margin: 5% 0 5% 0;
}

.copyright-icon {
  color: #626262;
}
