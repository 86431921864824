.container-Home {
  display: flex;
  align-items: center;
  background-color: #f0efeb;
  min-height: 100vh;
  width: 100%;
}

.hero-section {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.hero-text {
  margin-left: 60px;
  z-index: 1;
}

.profilepic {
  position: absolute;
  bottom: 0;
  right: -50px;
}

.content {
  background-color: #ffffff;
  width: 100%;
  height: 80vh;
  margin: 24px 32px;
  position: relative;
  border-radius: 50px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-text {
  animation: fadeIn 1s ease-in-out;
  line-height: 1.7;
}
.description {
  font-family: "SF-Text-Thin";
  font-size: 20px;
  max-width: 450px;
  margin: 0;
}
.AdditionalProjects {
  background-color: black;
  height: 1000px;
}
.my-name {
  font-family: "SF-Semibold";
  font-size: 32px;
}

.arrow {
  font-size: 30px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  animation: bounce 2s infinite;
}

h1 {
  font-family: SF-Semibold;
}

.hero-section h1 {
  margin: 8px 0;
}

.title {
  font-family: SF-Semibold;
  font-size: 52px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.scroll-to-top {
  background-color: white;
  color: black;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.scroll-arrow {
  width: 40px;
  font-size: 24px;
}

.AdditionalProjects {
  background-color: white; /* initial background color */
  height: 80vh;
  transition: background-color 1s ease-in-out;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 100px 32px 100px 32px;
  margin: 0;
}

.AdditionalProjects.active {
  background-color: black;
}

.chatbotsection {
  background-color: #f0efeb; /* initial background color */
  transition: background-color 1s ease-in-out;
}

.chatbotsection.chatbot-active {
  background-color: white;
}

.footer-section.footerActive {
  background-color: white;
  color: black;
}

.footer-section {
  background-color: black;
  margin: 0;
  transition: background-color 1s ease-in-out;
}

.scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .container-Home {
    width: auto;
    height: 100vh;
  }
  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 40px 20px;
    gap: 8px;
  }

  .hero-section {
    display: flex;
    align-items: start;
    position: relative;
    height: 100%;
  }
  .my-name {
    font-size: 32px;
  }
  .title {
    font-size: 24px;
  }
  .content {
    border-radius: 20px;
  }
  .description {
    font-size: 20px;
  }
  .circle {
    height: 100%;
    border-radius: 50%;
    background: radial-gradient(circle, #f6c6d9, #f5c3d0, #f4babb, #f7d1d7);
    filter: blur(100px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .profilepic {
    position: absolute;
    bottom: 0;
    left: -145px;
    height: 390px;
  }

  .AdditionalProjects {
    flex-direction: column;
  }
}
@media screen and (min-width: 1500px) {
  .my-name {
    font-size: 40px;
  }
  .title {
    font-size: 52px;
  }
  .profilepic {
    position: absolute;
    bottom: 0;
    right: 50px;
    height: 650px;
  }
}
