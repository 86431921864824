@font-face {
  font-family: "SF-Semibold";
  src: url("./Fonts/SF-Pro-Text-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "SF-Light";
  src: url("./Fonts/SF-Pro-Display-Light.otf") format("opentype");
}
@font-face {
  font-family: "Adorage";
  src: url("./Fonts/Adorage.otf") format("opentype");
}
@font-face {
  font-family: "SF-Text-Light";
  src: url("./Fonts/SF-Pro-Text-Light.otf") format("opentype");
}
@font-face {
    font-family: "SF-Text-Thin";
    src: url("./Fonts/SF-Pro-Text-Thin.otf") format("opentype");
  }
  @font-face {
    font-family: "SF-Text-Ultralight";
    src: url("./Fonts/SF-Pro-Text-Ultralight.otf") format("opentype");
  }

h1 {
  font-size: 52px;
  font-family: "SF-Semibold";
}

h2 {
  font-size: 32px;
  font-family: "SF-Semibold";
}

h3 {
  font-size: 24px;
  font-family: "SF-Semibold";
}
h4 {
  font-size: 16px;
  font-family: "SF-Text-Thin";
}
h6 {
  font-size: 16px;
  font-family: "SF-Semibold";
}

h5{
  font-size: 20px;
  font-family: "SF-Semibold";
}

.Subtitle{
  text-align: center;
}

* {
  margin: 0 0 0 0;
  scroll-behavior: smooth;
  overflow-x: hidden;

}

body, html {
  margin: 0;
  padding: 0;
}



p {
  font-family: SF-light;
  font-size: 16px;
  line-height: 1.8;
}

.center-text{
  text-align: center;
}
.margin{
  margin: 10% 0 10% 0;
}
.margin-small{
  margin: 5% 0 5% 0;
}

.margin-extrasmall{
  margin: 2% 0 2% 0;
}

.space{
margin-top: 10%;}

li {
  font-family: SF-light;
  font-size: 16px;
  line-height: 1.8;
}
